<template>
  <div class="new-program pb-12 pt-10">
    <v-row>
      <v-col sm="8">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">Recipe Details</h2>
          <v-btn
            class="primary--text mr-5"
            text
            depressed
            :loading="form.$busy"
            @click="saveForLater"
          >
            Save & Finish Later
          </v-btn>

          <v-btn
            v-if="hasChanges"
            class="bg-primary-gradient primary next-button px-12"
            @click="saveAndNext"
            :loading="form.$busy"
            text
            depressed
          >
            Save & Next
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>

          <v-btn
            v-if="!hasChanges && $attrs.id"
            class="bg-primary-gradient primary next-button px-12"
            :to="{
              name: 'form.recipe.ingredients',
              params: { id: $attrs.id },
            }"
            text
            depressed
          >
            Next
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cp sm="8">
        <form autocomplete="off">
          <recipe-details-form :form-data="form" @changeImage="changeImage" />
        </form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RecipeDetailsForm from '../components/RecipeDetailsForm'
import Event from '@/services/eventBus'
import Form from '@/utils/form'

import { pick, isNull, isEqual, filter } from 'lodash'
import { mapActions, mapState } from 'vuex'
import { mdiArrowRight } from '@mdi/js'

export default {
  name: 'RecipeDetailsTab',

  components: {
    RecipeDetailsForm,
  },

  data() {
    return {
      icons: {
        next: mdiArrowRight,
      },
      form: new Form({
        id: this.$attrs.id,
        type: 1, // 1 - Recipe, 2 - Food
        title: null,
        description: null,
        is_raw: false,
        is_alcohol: false,
        is_flavor_booster: false,
        serving_quantity: null,
        serving_unit: null,
        calories: 0,
        protein: 0,
        fats: 0,
        carbs: 0,
        fibre: 0,
        ethanol: 0,
        raw: {
          food_id: null,
          serving_quantity: null,
          serving_unit: null,
        },
        categories: [],
        restrictions: [],
        image_public_id: null,
        cloudinary_image: null,
        completed: false,
      }),

      dataCopy: null,
    }
  },

  computed: {
    ...mapState({
      selectedData: (state) => state.foods.selectedData,
    }),

    hasChanges() {
      return !isEqual(this.form.$data(), this.dataCopy)
    },
  },

  methods: {
    ...mapActions({
      getSingleData: 'foods/getSingleData',
      saveData: 'foods/saveData',
    }),

    cloneData() {
      this.dataCopy = this.form.$data()
    },

    async fetchData(id) {
      let { data } = await this.getSingleData(id)
      this.fillForm(data)
      this.cloneData()
    },

    changeImage() {
      this.form.image = this.selectedData.image
    },

    saveForLater() {
      this.form.$busy = true
      this.form.$clearErrors()

      const payload = this.form.$data()
      delete payload.image

      if (payload.restrictions && payload.restrictions[0]?.id)
        payload.restrictions = payload.restrictions.map((o) => o.id)

      this.saveData(payload)
        .then((data) => {
          Event.$emit('update-draft-foods-list')

          this.form.$timeout(() => {
            this.form.$busy = false
            this.$router.push({
              name: data.completed_at ? 'active.recipe' : 'draft.recipe',
            })
          })
        })
        .catch((err) => {
          this.form.$timeout(() => {
            this.form.$busy = false

            if (err.response && err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
            }
          })
        })
    },

    saveAndNext(completed) {
      this.form.$busy = true
      this.form.$clearErrors()

      const payload = this.form.$data()
      if (completed) {
        payload.completed = true
      }

      delete payload.image

      if (payload.restrictions && payload.restrictions[0]?.id)
        payload.restrictions = payload.restrictions.map((o) => o.id)

      this.saveData(payload)
        .then((data) => {
          this.form.$timeout(() => {
            this.form.$busy = false
            this.$router.push({
              name: 'form.recipe.ingredients',
              params: { id: data.id },
            })
          })
        })
        .catch((err) => {
          this.form.$timeout(() => {
            if (err.response && err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
            }

            this.form.$busy = false
          })
        })
    },

    fillForm(data) {
      data = pick(data, [
        'id',
        'type',
        'title',
        'description',
        'is_alcohol',
        'is_flavor_booster',
        'is_raw',
        'serving_quantity',
        'serving_unit',
        'calories',
        'protein',
        'fats',
        'carbs',
        'fibre',
        'ethanol',
        'raw',
        'image',
        'categories',
        'cloudinary_image',
        'completed',
        'restrictions',
      ])

      if (isNull(data.raw)) {
        data.raw = {
          food_id: null,
          serving_quantity: null,
          serving_unit: null,
        }
      } else {
        data.raw = {
          food_id: data.raw.id,
          serving_quantity: data.raw.serving_quantity,
          serving_unit: data.raw.serving_unit,
        }
      }

      data.categories = filter(data.categories, (i) => {
        return i !== 0
      })

      if (!data.cloudinary_image) {
        data['image_public_id'] = null
        data['cloudinary_image'] = null
      } else {
        data['image_public_id'] = data.cloudinary_image.public_id
      }

      this.form = new Form({ ...this.form, ...data })
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$attrs.id) {
        vm.fetchData(vm.$attrs.id)
      }
    })
  },
}
</script>

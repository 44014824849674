<template>
  <div class="bbr-program--details-form">
    <v-row>
      <v-col cols="12" md="8">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="form.image_public_id"
              label="Image ID"
              :error-messages="form.$getError('image_public _id')"
              hide-details
              outlined
              flat
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="form.title"
              label="Title"
              :error-messages="form.$getError('title')"
              :hide-details="!form.$getError('title')"
              outlined
              flat
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="form.description"
              label="Notes"
              rows="3"
              hide-details
              outlined
              flat
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.serving_quantity"
              label="Serving"
              :error-messages="form.$getError('serving_quantity')"
              :hide-details="!form.$getError('serving_quantity')"
              outlined
              flat
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.serving_unit"
              label="Unit"
              :error-messages="form.$getError('serving_unit')"
              :hide-details="!form.$getError('serving_unit')"
              outlined
              flat
            />
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="form.categories"
              :items="categories"
              label="Category"
              item-value="id"
              item-text="name"
              hide-details
              multiple
              outlined
            >
              <template v-slot:selection="{ item }">
                <v-chip color="primary"> {{ item.name }} </v-chip>
              </template>
            </v-select>
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="form.restrictions"
              :items="restrictions"
              label="Restrictions"
              item-value="id"
              item-text="name"
              hide-details
              multiple
              outlined
            >
              <template v-slot:selection="{ item }">
                <v-chip color="primary"> {{ item.name }} </v-chip>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="4">
        <cloudinary-image
          v-if="form.cloudinary_image"
          :image="form.cloudinary_image"
          :tile="true"
          class="primary lighten-4 rounded-lg mt-3"
          alt="Food Recipe"
          size="280"
          contain
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card class="mx-auto py-5" height="450" outlined>
          <v-simple-table class="mx-4">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" colspan="2">
                    <span class="title"> Macros per Serving </span>
                  </th>
                  <th class="text-right">%</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="width: 250px">Calories</td>
                  <td style="width: 170px">
                    <v-text-field
                      v-model="caloryValue"
                      hide-details
                      outlined
                      readonly
                      dense
                      flat
                    />
                  </td>
                  <td />
                </tr>

                <tr>
                  <td>Protein</td>
                  <td>
                    <v-text-field
                      v-model="form.protein"
                      :error-messages="form.$getError('protein')"
                      hide-details
                      outlined
                      dense
                      flat
                    />
                  </td>
                  <td class="text-right">{{ proteinValue.toFixed(1) }}</td>
                </tr>

                <tr>
                  <td>Fats</td>
                  <td>
                    <v-text-field
                      v-model="form.fats"
                      :error-messages="form.$getError('fats')"
                      hide-details
                      outlined
                      dense
                      flat
                    />
                  </td>
                  <td class="text-right">{{ fatsValue.toFixed(1) }}</td>
                </tr>

                <tr>
                  <td>Carbs</td>
                  <td>
                    <v-text-field
                      v-model="form.carbs"
                      :error-messages="form.$getError('carbs')"
                      hide-details
                      outlined
                      dense
                      flat
                    />
                  </td>
                  <td class="text-right">{{ carbsValue.toFixed(1) }}</td>
                </tr>

                <tr>
                  <td>Fibre</td>
                  <td>
                    <v-text-field
                      v-model="form.fibre"
                      :error-messages="form.$getError('form.fibre')"
                      hide-details
                      outlined
                      dense
                      flat
                    />
                  </td>
                  <td class="text-right" />
                </tr>

                <tr v-if="form.is_alcohol">
                  <td>Ethanol</td>
                  <td>
                    <v-text-field
                      v-model="form.ethanol"
                      :error-messages="form.$getError('form.ethanol')"
                      hide-details
                      outlined
                      dense
                      flat
                    />
                  </td>
                  <td class="text-right">{{ ethanolValue.toFixed(1) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import CloudinaryImage from '@/components/elements/CloudinaryImage'
import FoodCategory from '@/models/FoodCategory'
import Form from '@/utils/form'
import { isNaN } from 'lodash'
import Food from '@/models/Food'

export default {
  name: 'RecipeForm',

  props: {
    image: {
      type: Object,
    },
    formData: {
      type: Form,
      required: true,
    },
  },

  components: {
    CloudinaryImage,
  },

  data() {
    return {
      form: this.formData,
      categories: [],
      restrictions: [],
    }
  },

  watch: {
    formData() {
      this.form = this.formData
    },
    caloryValue(val) {
      this.form.calories = val
    },
  },

  computed: {
    caloryValue() {
      const calories =
        this.form.protein * 4 +
        this.form.fats * 9 +
        this.form.carbs * 4 +
        this.form.ethanol * 7

      return calories > 0 ? Math.round(calories) : this.form.calories
    },

    proteinValue() {
      let value = ((this.form.protein * 4) / this.caloryValue) * 100
      return value > 0 || !isNaN(value) ? value : 0
    },

    fatsValue() {
      const value = ((this.form.fats * 9) / this.caloryValue) * 100
      return value > 0 || !isNaN(value) ? value : 0
    },

    carbsValue() {
      const value = ((this.form.carbs * 4) / this.caloryValue) * 100
      return value > 0 || !isNaN(value) ? value : 0
    },

    ethanolValue() {
      const value = ((this.form.ethanol * 7) / this.caloryValue) * 100
      return value > 0 || !isNaN(value) ? value : 0
    },
  },

  created() {
    this.getFoodCategories()
    this.getRecipeRestrictions()
  },

  methods: {
    ...mapActions({
      changeImage: 'foods/changeImage',
    }),

    ...mapMutations({
      imageRemoved: 'foods/imageRemoved',
    }),

    rawFoodChange(value) {
      this.form.raw.food_id = value
    },

    async imageChange(image) {
      let data = new FormData()
      data.append('id', this.formData.id)
      data.append('image', image)
      await this.changeImage(data)

      this.$emit('changeImage')
    },

    async getFoodCategories() {
      const query = FoodCategory
      const { data } = await query.where('is_booster', false).get()
      this.categories = data
    },

    async getRecipeRestrictions() {
      const { data } = await Food.custom('foods/recipe/restrictions').get()

      this.restrictions = data
    },
  },
}
</script>

<style lang="scss" scoped>
.v-data-table {
  tbody {
    tr {
      td {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
      }

      &:hover {
        &:not(.v-data-table__expanded__content) {
          background: #ffffff !important;
        }
      }
    }
  }
}
</style>
